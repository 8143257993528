


























































import {
  SfHeading,
  SfButton,
  SfCheckbox,
  SfLoader,
} from '@storefront-ui/vue';
import FtButton from "~/components/customSFUI/vue/src/components/atoms/FtButton/FtButton.vue";
import { ValidationObserver } from 'vee-validate';
import {
  ref,
  computed,
  onMounted,
  useRouter,
  defineComponent,
  useContext,
} from '@nuxtjs/composition-api';
import userBillingGetters from '~/modules/customer/getters/userBillingGetters';
import { useCountrySearch, useUiState } from '~/composables';
import useShipping from '~/modules/checkout/composables/useShipping';
import useBilling from '~/modules/checkout/composables/useBilling';
import { useUser } from '~/modules/customer/composables/useUser';
import { useUserAddress } from '~/modules/customer/composables/useUserAddress';
import UserAddressDetails from '~/components/UserAddressDetails.vue';
import {
  addressFromApiToForm,
  CheckoutAddressForm,
  findUserAddressIdenticalToSavedCartAddress,
  formatAddressReturnToData,
  getInitialCheckoutAddressForm,
} from '~/helpers/checkout/address';
import { mergeItem } from '~/helpers/asyncLocalStorage';
import { isPreviousStepValid } from '~/helpers/checkout/steps';
import GA4Checkout from '~/modules/fortytwo/googleanalytics4/components/GA4Checkout.vue';
import type { Country, Customer, CustomerAddress } from '~/modules/GraphQL/types';
import { useAddresses } from '~/modules/customer/composables/useAddresses';
import AddressListModal from '~/modules/fortytwo/checkout/components/AddressListModal.vue';

export default defineComponent({
  name: 'BillingStepV2',
  components: {
    SfHeading,
    SfButton,
    SfCheckbox,
    SfLoader,
    FtButton,
    ValidationObserver,
    UserBillingAddresses: () => import('~/modules/checkout/components/V2/UserBillingAddresses.vue'),
    UserAddressDetails,
    GA4Checkout,
    AddressListModal,
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const userBilling = ref<Customer | null>(null);
    const {
      save, load: loadBilling, loading,
    } = useBilling();
    const {
      load: loadUserBilling,
      setDefaultAddress,
    } = useUserAddress();
    const {
      load: loadShipping,
      loading: isShippingLoading,
    } = useShipping();
    const {
      load: loadCountries,
      search: searchCountry,
    } = useCountrySearch();
    const { load: loadUseAddress } = useAddresses();
    const { isAddressListModalOpen, toggleAddressListModal } = useUiState();
    const countries = ref<Country[]>([]);
    const { isAuthenticated } = useUser();
    const billingDetails = ref<CheckoutAddressForm>(getInitialCheckoutAddressForm());
    const currentAddressId = ref<number | null>(null);
    const useCustomerAddresses = ref([]);
    const sameAsShipping = ref(false);
    const setAsDefault = ref(false);
    const isBillingDetailsStepCompleted = ref(false);
    const showBillingAddress = ref(false);
    const addressFormAction = ref('edit');
    const isSavingCompleted = ref(true);
    const canMoveForward = computed(() => !loading.value && !isShippingLoading.value && billingDetails.value && Object.keys(
      billingDetails.value,
    ).length > 0);

    const handleOpenNewAddressForm = () => {
      toggleAddressListModal();
      addressFormAction.value = 'create new';
    };

    const handleCloseAddressModal = () => {
      toggleAddressListModal();
      addressFormAction.value = 'edit';
    };

    const handleAddressFormSubmit = (address) => {
      handleSetCurrentAddress(address);
      handleAddressSubmit();
    };

    const handleAddressSubmit = async () => {
      if(!billingDetails.value.postcode) {
        handleOpenNewAddressForm();
        return;
      }
      isSavingCompleted.value = false;
      const addressId = currentAddressId.value;
      const billingDetailsData = {
        billingDetails: {
          ...billingDetails.value,
          customerAddressId: addressId === null ? null : String(addressId),
          sameAsShipping: sameAsShipping.value,
          save_in_address_book: false,
        },
      };
      await save(billingDetailsData);
      if (addressId !== null && setAsDefault.value) {
        const [chosenAddress] = userBillingGetters.getAddresses(
          userBilling.value,
          { id: addressId },
        );
        chosenAddress.default_billing = setAsDefault.value;
        if (chosenAddress) {
          await setDefaultAddress({ address: chosenAddress });
          userBilling.value = await loadUserBilling(true);
        }
      }
      await mergeItem('checkout', { billing: billingDetailsData });
      await router.push(app.localeRoute({ name: 'v2-delivery-date' }));
      isBillingDetailsStepCompleted.value = true;
      isSavingCompleted.value = true;
    };

    const handleSetCurrentAddress = async (customerAddress: CustomerAddress) => {
      const id = customerAddress?.id ?? null;
      currentAddressId.value = id;
      billingDetails.value = addressFromApiToForm(customerAddress);
      isBillingDetailsStepCompleted.value = false;
    };

    onMounted(async () => {
      const validStep = await isPreviousStepValid('shipping');
      if (!validStep || !isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'v2-shipping' }));
      }
      const [loadedBillingInfoBoundToCart, loadedUserBilling, loadedCountries, addressesResponse] = await Promise.all([
        loadBilling(),
        loadUserBilling(),
        loadCountries(),
        loadUseAddress()
      ]);
      useCustomerAddresses.value = addressesResponse;
      const [defaultAddress = null] = useCustomerAddresses.value.filter((address)=> address.default_billing === true);
      const wasBillingAddressAlreadySetOnCart = Boolean(loadedBillingInfoBoundToCart);

      // keep in mind default billing address is set on a customer's cart during cart creation
      if (wasBillingAddressAlreadySetOnCart) {
        const userAddressIdenticalToSavedCartAddress = findUserAddressIdenticalToSavedCartAddress(
          useCustomerAddresses?.value,
          loadedBillingInfoBoundToCart,
        );

        await handleSetCurrentAddress({ ...loadedBillingInfoBoundToCart, id: userAddressIdenticalToSavedCartAddress?.id });
      } else if (defaultAddress) {
        await handleSetCurrentAddress(defaultAddress);
      } else if (useCustomerAddresses.value.length > 0) {
        await handleSetCurrentAddress(useCustomerAddresses.value[0]);
      } else {
        handleOpenNewAddressForm();
      }

      userBilling.value = loadedUserBilling;
      countries.value = loadedCountries;
      showBillingAddress.value = true;
    });

    return {
      countries,
      billingDetails,
      currentAddressId,
      useCustomerAddresses,
      showBillingAddress,
      setAsDefault,
      handleAddressSubmit,
      isAuthenticated,
      addressFormAction,
      isAddressListModalOpen,
      handleCloseAddressModal,
      handleAddressFormSubmit,
      isShippingLoading,
      loading,
      isBillingDetailsStepCompleted,
      canMoveForward,
      isSavingCompleted,
    };
  },
});
